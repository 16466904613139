@import "../../scss/main";

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 3rem);
  height: fit-content;
  padding: 2rem;
  width: 100%;
  background-color: $SkyBlue;
  color: $TextColor;

  @include sm {
    padding: 2rem 0;
  }

  .login_form {
    background-color: $WhiteColor;
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
    max-width: calc(100% - 20px);
    box-shadow: 0px 0px 25px 5px rgba(0, 0, 0, 0.2);

    @include sm {
      box-shadow: none;
      padding: 1rem ;
    }

    form {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    button {
      height: 2.75rem;
      width: 100%;
      background-color: $PurpleColor;
      color: $WhiteColor;
      font-size: 18px;
      margin-bottom: 1rem;
      border-radius: 8px;
    }

    h3 {
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }

  .login_passwordInput {
    position: relative;

    img {
      position: absolute;
      top: 36px;
      right: 1rem;
      height: 1rem;
      cursor: pointer;
    }
  }

  .login_forgetPassword {
    color: $TextColor;
    cursor: pointer;
    text-decoration: underline;
    width: fit-content;
  }
}
