.home {
 min-height: 100vh;
 background-color: #F3F8FD;
}


.home_pageContainer {
 padding: 20px 0;
 background-color: #F3F8FD;
 overflow: auto;
 display: flex;
 justify-content: center;
 align-items: center;
 flex-direction: column;
}

.home_pageContainer h3 {
 color: #111827;
 text-align: center;
 margin-bottom: 0.5rem;
}

.home_pageWrapper {
 max-width: 100vw;
 overflow: auto;
}

.home_page {
 background-color: #fff;
 padding: 20px 25px;
 min-width: fit-content;
 width: fit-content;
 margin: 0 auto;
}

.home_allergiesInput {
 width: 70%;
}

.home_allergiesInput input {
 width: inherit;
}

.home_pageTop {
 display: flex;
 justify-content: space-between;
 align-items: center;
 margin-bottom: 0.25rem;
 background-color: #fff;
}

.home_pageTopDate {
 display: flex;
 gap: 0.5rem;
}

.home_pageTop2 {
 display: flex;
 justify-content: space-between;
 margin-bottom: 0.25rem;
}

.home_smallDatePicker input {
 width: 40px;
}


.home_table {
 border: 1px solid;
}

.home_tableHeader {
 display: flex;
 align-items: flex-start;
 white-space: nowrap;


}

.home_tableHeader span {


 min-width: 24px;
 height: 24px;
 border-right: 1px solid;
 border-bottom: 1px solid;
 line-height: 1;
 text-align: center;
 display: inline-flex;
 font-size: 14px;
 font-weight: 500;
 justify-content: center;
 align-items: center;


}

.home_tableHeader span:first-child {
 width: 250px;
 font-weight: 600;
}

.home_tableHeader span:nth-child(2) {
 width: 60px;
}

.home_tableHeader span:last-child {
 border-right: none;
}

.sampleTableBox {
 min-width: 24px;
 height: 32px;
 border-right: 1px solid;
 border-bottom: 1px solid;
 line-height: 1;
 text-align: center;
 display: inline-flex;
 font-size: 16px;
 justify-content: center;
 align-items: center;


}

.sampleTableBox:last-child {
 border-right: none;
}

.home_tableRow {
 display: flex;

}


.home_tableRowMedication {
 width: 250px;


}

.home_tableRowMedication span {
 position: relative;
 display: inline-block;
 height: 100%;
 width: 100%;



}

.home_tableRowMedication span textarea {
 position: absolute;
 height: 100%;
 width: 100%;
 resize: none;
 border: none;
 outline: none;
 padding: 0.3rem 0.5rem;
 font-family: "Roboto";
 overflow: hidden;
 border-right: 1px solid;
 border-bottom: 1px solid;
 font-size: 13px;
 line-height: 1.2;
 background: transparent;
 z-index: 2;
 resize: none;
 max-height: 100%;
}

.home_tableRowMedication span textarea:not(:placeholder-shown)+.home_tableRowMedicationPlaceholder {
 display: none;
}

.home_tableRowMedicationPlaceholder {
 position: absolute;
 display: flex;
 flex-direction: column;
 height: 100%;
 width: 100%;
 padding: 0.3rem 0.5rem;

}

.home_tableRowMedicationPlaceholder span {
 font-size: 13px;
 line-height: 1.2;
 white-space: nowrap;
 font-family: "Roboto";
 font-style: italic;
 color: #ccc;
}

.home_tableRowTime {
 display: flex;
 flex-direction: column;
 width: 60px;


}

.home_tableRowTime span {
 min-width: 24px;
 line-height: 1;
 text-align: center;
 display: inline-flex;
 font-size: 16px;
 justify-content: center;
 align-items: center;

 position: relative;
 height: 18px;



}

.home_tableRowTime span input {
 height: 100%;
 width: 100%;
 border-top: none;
 border-left: none;
 outline: none;
 font-size: 14px;
 padding: 0 0.25rem;
 text-align: center;
 font-style: italic;
 border-bottom: 1px solid black;
 border-right: 1px solid black;
 line-height: 0.8;
 box-sizing: border-box;

}


.home_tableRowBoxes div {
 height: fit-content;
 display: flex;
}

.home_tableRowBoxes div span {
 min-width: 24px;
 border-right: 1px solid;
 border-bottom: 1px solid;
 text-align: center;
 display: inline-flex;
 justify-content: center;
 align-items: center;

 font-size: 0;
 line-height: 0;
 height: 18px;
}

.home_tableRowBoxes div span:last-child {
 border-right: none;
}


.home_tableBottom {
 display: grid;
 grid-template-columns: repeat(3, 1fr);

}


.home_tableNotes {
 width: 100%;
 display: flex;
 flex-direction: column;


}

.home_tableNotes span {
 width: 100%;
 display: inline-flex;
 border-bottom: 1px solid;
 border-right: 1px solid;
 height: 30px;


}

.home_tableNotes span:first-child {
 height: 18px;
 font-size: 12px;
 font-weight: 600;
 padding-left: 1rem;
 line-height: 18px;
}

.home_tableNotes span:last-child {
 border-bottom: none;
}

.home_tableSign {
 display: flex;
}


.home_tableSign div {
 width: auto;
 width: 80%;
 display: flex;
 flex-direction: column;
}

.home_tableSign div:last-child {
 width: 20%;
}

.home_tableSign div span {
 width: 100%;
 display: inline-flex;
 border-bottom: 1px solid;
 border-right: 1px solid;
 height: 30px;


}

.home_tableSign div span:first-child {
 height: 18px;
 font-size: 12px;
 font-weight: 600;
 line-height: 18px;
 display: inline-flex;
 justify-content: center;
 background-color: #C0C0C0;
}

.home_tableSign div span:last-child {
 border-bottom: none;
}



.home_tableSign:last-child div:last-child span {
 border-right: none;
}




.home_button {
 padding: 0.5rem 1rem;
 background-color: #6366f1;
 color: #fff;
 margin-top: 1rem;
 border-radius: 6px;
}

@media print {
 @page {
  size: A4 landscape;
 }

 .home_tableRowMedicationPlaceholder {
  display: none;
 }

 .navbar {
  display: none;
 }

 .home_pageContainer h3 {
  display: none;
 }
}