@import "../../scss/main";

.navbar {
  height: 3rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;

  @include md {
    flex-direction: column;
    gap: 0.5rem;
    height: auto;
  }

  .navbar_header {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  a {
    text-decoration: none;
  }

  .navbar_title {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 14px;
    padding: 0 1rem;
    letter-spacing: 1px;
    cursor: pointer;
    text-decoration: none;
    color: $TextColor;
  }

  .navbar_inputOptionsInput {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;

    label {
      font-size: 12px;
    }

    input {
      height: 1.5rem;
      width: 100%;
      outline: none;
      background-color: #f3f4f6;
      border: 1px solid #9ca3af;
      padding: 0 0.5rem;
      border-radius: 4px;
      box-sizing: border-box;
      font-size: 12px;

      &::placeholder {
        color: #9ca3af;
      }
    }
  }

  .navbar_searchContainerDesktop {
    @include md {
      display: none;
    }
  }

  .navbar_searchContainerMobile {
    display: none;

    @include md {
      display: block;
    }

    @include sm {
      width: 100%;
    }
  }

  .navbar_inputContainer {
    position: relative;
    height: 2rem;
    background-color: #f3f4f6;
    width: 16rem;

    @include sm {
      width: 100%;
    }

    img {
      height: 1rem;
      position: absolute;
      top: 50%;
      left: 0.5rem;
      transform: translateY(-50%);
    }

    & > input {
      height: 100%;
      width: 100%;
      padding-left: 1.75rem;
      outline: none;
      background-color: transparent;
      border: 1px solid #9ca3af;
      border-radius: 4px;
      box-sizing: border-box;

      &::placeholder {
        color: #9ca3af;
      }
    }
  }

  .navbar_btns {
    display: flex;
    gap: 1rem;
  }

  .navbar_greenBtn {
    background-color: rgba(16, 185, 129, 0.15);
    height: 2rem;
    border: 1px solid rgba(5, 150, 105, 0.25);
    color: rgba(16, 185, 129, 1);
    padding: 0 1rem;
    border-radius: 4px;
  }

  .navbar_purpleBtn {
    background-color: $PurpleColor;
    height: 2rem;
    border: 1px solid rgba(5, 150, 105, 0.25);
    color: $WhiteColor;
    padding: 0 1rem;
    border-radius: 4px;
  }

  .navbar_inputOptions {
    max-height: 200px;
    overflow: auto;
    position: absolute;
    top: 100%;
    background-color: $WhiteColor;
    width: 100%;
    margin: 0.5rem 0;
    border-radius: 5px;
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.2);
    z-index: 100;

    h4 {
      font-size: 14px;
      line-height: 1.1;
      margin: 0;
      text-align: center;
      margin: 1rem;
    }

    .navbar_inputOption {
      color: $TextColor;
      padding: 0.25rem 0.5rem;
      cursor: pointer;

      &:hover {
        background-color: #9ca3af3e;
      }

      &:not(:last-child) {
        border-bottom: 1px solid #9ca3af;
      }

      h3 {
        font-size: 14px;
        line-height: 1.1;
        margin: 0;
      }

      p {
        font-size: 12px;
        margin: 0;
        line-height: 1.2;
      }
    }
  }
}
