@import "../../scss/main";

.formikInput {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  label {
    font-size: 14px;
    line-height: 1.1;
    color: $TextColor;
    margin-bottom: 6px;
    font-family: "Roboto";
  }



  input,
  textarea {
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    height: 2.75rem;
    width: 100%;
    border: 1px solid $TextColor;
    outline: none;
    padding: 0 12px;
    color: $TextColor;
    margin-bottom: 2px;
    font-size: 1rem;
    font-family: "Roboto";

    &::-webkit-input-placeholder {
      color: inherit;
      font-size: inherit;
      font-family: inherit;
      font-weight: inherit;
      color: $HeadingBg;
    }
  }

  textarea {
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    min-height: 4rem;
    width: 100%;
    border: 1px solid $TextColor;
    outline: none;
    padding: 0.62rem 0.88rem;
    color: $HeadingBg;
    font-size: 1rem;
    box-shadow: 0px 1px 2px 0px $shadow;
    font-family: "Roboto";

    &::before,
    &::after {
      content: "";
      background-color: $HeadingBg;
    }
  }

  .formikInput_error {
    border: 2px solid $ErrorColor !important;
    font-family: "Roboto";
  }

  span {
    color: $ErrorColor;
    font-size: 12px;
    font-family: "Roboto";
  }
}
